  <template>
  <div class="flex-col text-14 white-bg-color">
    <div class="flex-row">
      <div class="flex-row width-half space-between">
        <div class="padding-10">合计金额</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ formatMoney(total.total, 2) }}
        </div>
      </div>
      <div class="flex-row width-half space-between">
        <div class="padding-10">合计成本</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ formatMoney(total.cost, 2) }}
        </div>
      </div>
    </div>
    <div class="flex-row" v-if="total.comix_price > 0">
      <div class="flex-row width-half space-between">
        <div class="padding-10">平台扣点</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ formatMoney(total.comix_tariff, 0) }}%
        </div>
      </div>
      <div class="flex-row width-half space-between">
        <div class="padding-10">平台金额</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ formatMoney(total.comix_price, 2) }}
        </div>
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-row width-half space-between">
        <div class="padding-10">合计毛利</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ formatMoney(total.profit, 2) }}
        </div>
      </div>
      <div class="flex-row width-half space-between">
        <div class="padding-10">总毛利率</div>
        <div class="padding-10 red-font-color text-16 bold">
          {{ total.rate }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/format.js";

export default {
  props: {
    total: {
      total: null,
      cost: null,
      profit: null,
      rate: null,
      comix_tariff: null,
      comix_price: null,
    },
  },
  methods: {
    formatMoney: formatMoney,
  },
};
</script>
